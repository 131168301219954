.portal {
  box-sizing: border-box;
  header {
    height: 0;
    color: #fff;
    nav {
      width: 100%;
      padding: 16px;
    }
  }

  main {
    margin-bottom: 60px;
    .header {
      color: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      padding-top: 4rem;
      padding-bottom: 4rem;

      h1 {
        font-size: 3em;
      }

      @media (min-width: 768px) {
        min-height: 280px;
        padding-top: 6rem;
      }

      .container {
        text-align: center;
        @media (min-width: 992px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      &-bg {
        z-index: -1;
        max-width: 100vw;
        border-bottom: 1px solid #fff;
        // background-image: url('../../resource/bg.jpg');
        // background: linear-gradient(
        //   150deg,
        //   #16aa49 15%,
        //   #1ccf59 70%,
        //   #3bef78 94%
        // );
        // background: linear-gradient(
        //   150deg,
        //   #6f9faf 15%,
        //   #6f9f9c 70%,
        //   #577e89 94%
        // );
        background: linear-gradient(
          150deg,
          #7795f8 15%,
          #6772e5 70%,
          #555abf 94%
        );
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        span:first-child {
          left: -4%;
          bottom: auto;
          background: hsla(0, 0%, 100%, 0.1);
        }

        span:nth-child(2) {
          right: 4%;
          top: 10%;
          background: hsla(0, 0%, 100%, 0.1);
        }

        span:nth-child(3) {
          top: 280px;
          right: 5.66666%;
          background: hsla(0, 0%, 100%, 0.3);
        }

        span:nth-child(4) {
          top: 320px;
          right: 7%;
          background: hsla(0, 0%, 100%, 0.15);
        }

        span:nth-child(5) {
          top: 38%;
          left: 1%;
          right: auto;
          background: hsla(0, 0%, 100%, 0.05);
        }

        span:nth-child(6) {
          width: 200px;
          height: 200px;
          top: 44%;
          left: 10%;
          right: auto;
          background: hsla(0, 0%, 100%, 0.15);
        }

        span:nth-child(7) {
          bottom: 50%;
          right: 36%;
          background: hsla(0, 0%, 100%, 0.04);
        }

        span:nth-child(8) {
          bottom: 70px;
          right: 2%;
          background: hsla(0, 0%, 100%, 0.2);
        }

        span:nth-child(9) {
          bottom: 1%;
          right: 2%;
          background: hsla(0, 0%, 100%, 0.1);
        }

        span:nth-child(10) {
          bottom: 1%;
          left: 1%;
          right: auto;
          background: hsla(0, 0%, 100%, 0.05);
        }

        span {
          position: absolute;
          height: 120px;
          width: 120px;
          border-radius: 50%;
        }

        .span-200 {
          height: 200px;
          width: 200px;
        }

        .span-150 {
          height: 150px;
          width: 150px;
        }

        .span-100 {
          height: 100px;
          width: 100px;
        }

        .span-75 {
          height: 75px;
          width: 75px;
        }

        .span-50 {
          height: 50px;
          width: 50px;
        }
      }

      &-tail {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        svg {
          position: absolute;
          bottom: 0;
          polygon {
            fill: #fff;
          }
        }
      }
    }

    .input {
      transform: translateY(-50px);
    }

    .body {
      .title {
        font-size: 2em;
      }
      > * {
        margin-bottom: 24px;
      }
      li {
        margin-bottom: 5px;
      }
    }
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  }

  footer {
    text-align: center;
    margin-bottom: 24px;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1040px;
    }
  }
}
