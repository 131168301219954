.code {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    height: 30px;
    border-radius: 30px;
    padding-left: 13px;
    width: 100%;
    font-size: 1em;
    // transition: 0.5s width;

    @media (min-width: 768px) {
      height: 50px;
    }
  }

  button {
    background: transparent;
    border: 0;
    width: 50px;
    text-align: right;
    cursor: pointer;
    font-size: 1em;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }

    &.success {
      padding-top: 40px;
      margin-bottom: -57px;
    }
  }

  &-error {
    width: 100%;
    margin-left: 24px;
    margin-top: 5px;
    color: red;
  }
}
